import { NavigationHookAfter, Router, createRouter, createWebHistory } from 'vue-router';

import { authGuard } from '@/router/guards/auth.guard';
import { guestGuard } from '@/router/guards/guest.guard';
import { middleware } from '@/router/guards/middleware';
import { teamGuard } from '@/router/guards/team.guard';

// Router

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: [
        // User

        {
            path: '/',
            name: 'domains',
            component: () => import('../views/DomainArchiveView.vue'),
            meta: {
                auth: true,
                title: 'Økisnøvn',
            },
        },
        {
            path: '/domains/shared',
            name: 'domains-shared',
            component: () => import('../views/DomainArchiveSharedView.vue'),
            meta: {
                auth: true,
                title: 'Økisnøvn',
            },
        },
        {
            path: '/domains/:domainId',
            name: 'domains-single',
            component: () => import('../views/DomainView.vue'),
            meta: {
                auth: true,
                backOption: 'domains',
            },
        },
        {
            path: '/payment-methods',
            name: 'payment-methods',
            component: () => import('../views/PaymentMethodView.vue'),
            meta: {
                auth: true,
                title: 'Gjaldshættir',
            },
        },
        {
            path: '/search-domain',
            name: 'search-domain',
            component: () => import('../views/DomainSearchView.vue'),
            meta: {
                auth: true,
                title: 'Skráset økisnavn',
            },
        },
        {
            path: '/purchase-success',
            name: 'purchase-success',
            component: () => import('../views/DomainPurchaseSuccessView.vue'),
            meta: {
                auth: true,
                title: 'Keyp økisnavn',
            },
        },
        {
            path: '/purchase-failure',
            name: 'purchase-failure',
            component: () => import('../views/DomainPurchaseFailureView.vue'),
            meta: {
                auth: true,
                title: 'Keyp økisnavn',
            },
        },
        {
            path: '/transfer-domain',
            name: 'transfer-domain',
            component: () => import('../views/TransferView.vue'),
            meta: {
                auth: true,
                title: 'Flyt økisnavn',
            },
        },

        // General

        {
            path: '/my-user',
            name: 'my-user',
            component: () => import('../views/MyUserView.vue'),
            meta: {
                auth: true,
                title: 'Mín brúkari',
            },
        },

        // Teams

        {
            path: '/teams/:teamId/domains/:domainId',
            name: 'teams-domains-single',
            component: () => import('../views/DomainView.vue'),
            meta: {
                auth: true,
                backOption: 'domains',
            },
        },
        {
            path: '/teams/:teamId/domains',
            name: 'teams-domains',
            component: () => import('../views/DomainArchiveTeamView.vue'),
            meta: {
                auth: true,
            },
        },
        {
            path: '/teams/:teamId/search-domain',
            name: 'teams-search-domain',
            component: () => import('../views/DomainSearchView.vue'),
            meta: {
                auth: true,
                title: 'Skráset økisnavn',
            },
        },
        {
            path: '/teams/:teamId/purchase-success',
            name: 'teams-purchase-success',
            component: () => import('../views/DomainPurchaseSuccessView.vue'),
            meta: {
                auth: true,
                title: 'Keyp økisnavn',
            },
        },
        {
            path: '/teams/:teamId/purchase-failure',
            name: 'teams-purchase-failure',
            component: () => import('../views/DomainPurchaseFailureView.vue'),
            meta: {
                auth: true,
                title: 'Keyp økisnavn',
            },
        },
        {
            path: '/teams/:teamId/payment-methods',
            name: 'teams-payment-methods',
            component: () => import('../views/PaymentMethodView.vue'),
            meta: {
                auth: true,
                teamOwner: true,
                title: 'Gjaldshættir',
            },
        },
        {
            path: '/teams/:teamId',
            name: 'teams-single',
            component: () => import('../views/TeamView.vue'),
            meta: {
                auth: true,
                teamOwner: true,
            },
        },
        {
            path: '/teams/:teamId/transfer-domain',
            name: 'teams-transfer-domain',
            component: () => import('../views/TransferView.vue'),
            meta: {
                auth: true,
                teamOwner: true,
                title: 'Flyt økisnavn',
            },
        },

        // Auth

        {
            path: '/login',
            name: 'auth-login',
            component: () => import('../views/auth/LoginView.vue'),
            meta: {
                guest: true,
            },
        },
        {
            path: '/login/reset-password/:token',
            name: 'auth-reset-password',
            component: () => import('../views/auth/ResetPasswordView.vue'),
            meta: {
                guest: true,
                backOption: 'auth-login',
            },
        },
        {
            path: '/create-abroad-user',
            name: 'auth-create-abroad-user',
            component: () => import('../views/auth/CreateAbroadUserView.vue'),
            meta: {
                guest: true,
                title: 'Stovna brúkara',
                backOption: 'auth-login',
            },
        },
        {
            path: '/:any(.*)*',
            name: '404',
            component: () => import('../views/404View.vue'),
            meta: {
                title: '404: Síðan finnist ikki',
            },
        },

        // Paths from backend

        {
            path: '/teams/:teamId/invites/:inviteId/accept',
            name: 'team-invite-accept',
            component: () => import('../views/InviteAcceptView.vue'),
            meta: {
                auth: true,
            },
        },
        {
            path: '/domains/:domainId/invites/:inviteId/accept',
            name: 'domain-invite-accept',
            component: () => import('../views/InviteAcceptView.vue'),
            meta: {
                auth: true,
            },
        },

        // Admin

        {
            path: '/admin',
            name: 'admin',
            component: () => import('../views/admin/AdminView.vue'),
            meta: {
                auth: true,
            },
            children: [
                {
                    path: 'users',
                    name: 'admin-users',
                    component: () => import('../views/admin/AdminUsersView.vue'),
                },
                {
                    path: 'domains',
                    name: 'admin-domains',
                    component: () => import('../views/admin/AdminDomainsView.vue'),
                },
                {
                    path: 'orders',
                    name: 'admin-orders',
                    component: () => import('../views/admin/AdminOrdersView.vue'),
                },
                {
                    path: 'invoices',
                    name: 'admin-invoices',
                    component: () => import('../views/admin/AdminInvoicesView.vue'),
                },
                {
                    path: 'statistics',
                    name: 'admin-statistics',
                    component: () => import('../views/admin/AdminStatisticsView.vue'),
                },
                {
                    path: 'settings',
                    name: 'admin-settings',
                    component: () => import('../views/admin/AdminSettingsView.vue'),
                },
                {
                    path: 'activity-log',
                    name: 'admin-activity-log',
                    component: () => import('../views/admin/AdminActivityLogView.vue'),
                },
            ],
        },
    ],
});

registerNavigationGuards(router);

registerNavigationHooks();

export default router;

// Guards & Hooks

function registerNavigationGuards(router: Router) {
    router.beforeEach(middleware);
    router.beforeEach(authGuard);
    router.beforeEach(guestGuard);
    router.beforeEach(teamGuard);
}

function registerNavigationHooks(...hooks: NavigationHookAfter[]) {
    for (const hook of hooks) {
        router.afterEach(hook);
    }
}
